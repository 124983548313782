import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from '@layout/components/main-layout/main-layout.component';
import { LangGuard } from './lang.guard';

export const routes: Routes = [
  {
    path: ':language',
    canActivate: [LangGuard],
    children: [
      {
        path: 'consultation',
        loadChildren: () => import('./pages/consultation-page/consultation.module').then(m => m.ConsultationPageModule)
      },
      {
        path: 'visit',
        loadChildren: () => import('./pages/hybrid-visit-page/hybrid-visit-page.module').then(m => m.HybridVisitPageModule)
      },
      {
        path: 'transition',
        loadChildren: () => import('./pages/transitional-page/transitional-page.module').then(m => m.TransitionalPageModule)
      },
      {
        path: '',
        component: MainLayoutComponent,
        children: [
          {
            path: 'success',
            loadChildren: () => import('./pages/success/success.module').then(m => m.SuccessModule)
          },
          {
            path: '404',
            loadChildren: () => import('./pages/404/not-found.module').then(m => m.NotFoundModule)
          },
          {
            path: '**',
            loadChildren: () => import('./pages/dummy-url-tracker/dummy-url-tracker.module').then(m => m.DummyUrlTrackerModule)
          }
        ]
      }
    ]
  },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./pages/dummy-url-tracker/dummy-url-tracker.module').then(m => m.DummyUrlTrackerModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollOffset: [0, 0],
      scrollPositionRestoration: 'top',
      enableTracing: false,
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabledNonBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
