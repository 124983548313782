import { APP_INITIALIZER } from '@angular/core';
import { EnvironmentLoadService } from '../services/environment-load.service';
import { loadScript } from '@helpers/load-script.function';
import { getIfExistCookie } from '@helpers/cookie.helper';

export const gtmInitializerFactory = (env: EnvironmentLoadService) =>
  () => {
    const cookieConsent = getIfExistCookie('cookieConsent');

    if (cookieConsent && cookieConsent?.isCookiePolicyAccepted) {
      return cookieConsent?.allowedCookies?.includes('marketing') ? initGtm() : '';
    }

    window.addEventListener('policyAccepted', (event: CustomEvent) => {
      if (event.detail.includes('marketing')) {
        initGtm();
      }
    });

    return;

    function initGtm() {
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      });

      return new Promise(resolve => {
        loadScript('https://www.googletagmanager.com/gtm.js?id=' + env.environment.GTM_ID)
          .then(() => resolve(null))
          .catch(() => resolve(null))
      });
    }
  };

export const gtmInitializerProvider = {
  provide: APP_INITIALIZER,
  useFactory: gtmInitializerFactory,
  multi: true,
  deps: [EnvironmentLoadService]
};
