import { APP_BOOTSTRAP_LISTENER } from '@angular/core';
import { IEnvironment } from '@models';
import { EnvironmentLoadService } from '../services/environment-load.service';
import { getIfExistCookie } from '@helpers/cookie.helper';

const hotJarInitializerFactory = (environmentLoadService: EnvironmentLoadService<IEnvironment>) =>
  () => {
    const hotJarInit = () => {
      (function(h: any, o: any, t: any, j: any, a?: any, r?: any) {
        h.hj = h.hj || function() {(h.hj.q = h.hj.q || []).push(arguments);};
        h._hjSettings = {
          hjid: environmentLoadService.environment.HOTJAR_ID,
          hjsv: environmentLoadService.environment.HOTJAR_SNIPPET_VERSION
        };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }

    const cookieConsent = getIfExistCookie('cookieConsent');

    if (cookieConsent && cookieConsent?.isCookiePolicyAccepted) {
      if (cookieConsent?.allowedCookies?.includes('marketing')) {
        hotJarInit();
      }

      return;
    }

    window.addEventListener('policyAccepted', (event: CustomEvent) => {
      if (event.detail.includes('marketing')) {
        hotJarInit();
      }
    });

    return;
  };

export const hotJarInitializerProvider = {
  provide: APP_BOOTSTRAP_LISTENER,
  useFactory: hotJarInitializerFactory,
  multi: true,
  deps: [EnvironmentLoadService]
};
