import { Component, OnInit } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AbstractComponent } from '@helpers';

@Component({
  selector: 'apo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AbstractComponent implements OnInit {
  lazyModuleIsLoading: boolean;

  constructor(
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.checkLazyLoading();
  }

  private checkLazyLoading() {
    this.router.events.pipe(
      takeUntil(this.ngUnsubscribe$)
    ).subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.lazyModuleIsLoading = true;
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.lazyModuleIsLoading = false;
      }
    });
  }
}
