<div class="spinner">
  <svg height="50" id="bd0b6b61-9de3-4d73-ba9f-7f41a31fc646"
       viewBox="0 0 59.7 162.74" width="50" xmlns="http://www.w3.org/2000/svg"><title>pill</title>
    <style>
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -o-transform: rotate(0deg)
        }
        100% {
          transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
          -moz-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          -o-transform: rotate(360deg)
        }
      }

      @-webkit-keyframes rotate {
        0% {
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -o-transform: rotate(0deg)
        }
        100% {
          transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
          -moz-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          -o-transform: rotate(360deg)
        }
      }

      @-moz-keyframes rotate {
        0% {
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -o-transform: rotate(0deg)
        }
        100% {
          transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
          -moz-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          -o-transform: rotate(360deg)
        }
      }

      @-ms-keyframes rotate {
        0% {
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -o-transform: rotate(0deg)
        }
        100% {
          transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
          -moz-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          -o-transform: rotate(360deg)
        }
      }

      .animated {
        transform-origin: center center;
        -webkit-transform-origin: center center;
        -moz-transform-origin: center center;
        -o-transform-origin: center center;
        -ms-transform-origin: center center;
        animation-duration: 3s;
        -webkit-animation-duration: 3s;
        -moz-animation-duration: 3s;
        -o-animation-duration: 3s;
        -ms-animation-duration: 3s;
        animation-name: rotate;
        -webkit-animation-name: rotate;
        -moz-animation-name: rotate;
        -o-animation-name: rotate;
        -ms-animation-name: rotate;
        animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite;
        -ms-animation-iteration-count: infinite;
        animation-timing-function: linear;
        -webkit-animation-timing-function: linear;
        -moz-animation-timing-function: linear;
        -o-animation-timing-function: linear;
        -ms-animation-timing-function: linear
      }
    </style>
    <g class="animated">
      <path
        d="M58.73,81.37a1,1,0,0,0,1-1V29.17C59.7,13.13,46.27,0,29.85,0h0C13.43,0,0,13.13,0,29.17V80.4a1,1,0,0,0,1,1Z"
        fill="#e62144"/>
      <path d="M33.56,81.06V19.23a9.93,9.93,0,1,0-19.86,0V81.06Z" opacity="0.05"/>
      <g opacity="0.05">
        <path
          d="M33.47,81.27c-.65-13.51-1-26.25-1.08-39,0-3.17.08-6.32.19-9.49l.35-9.55.09-2.4,0-1.2c0-.39,0-.77,0-1.15A9.34,9.34,0,0,0,31.5,14.2a9.67,9.67,0,0,0-3.42-3.06c-.36-.15-.69-.35-1.06-.47l-.54-.19c-.19-.06-.38-.1-.56-.14a9.81,9.81,0,0,0-2.29-.26,9.36,9.36,0,0,0-4.37,1.21A9.49,9.49,0,0,0,16,14.39a9.36,9.36,0,0,0-1.37,4.17c0,.37,0,.72,0,1.11l0,1.21.07,2.42c.18,6.45.24,12.91.33,19.36,0,3.22.08,6.45.07,9.68v4.84l0,4.83q-.12,9.69-.4,19.36l-1-1,4.8.19c1.61.06,3.24.15,4.88.23Zm.19.2L23.88,82c-1.67.08-3.35.17-5.05.23l-5.13.19-1,0,0-1.06c-.18-6.45-.33-12.9-.4-19.36l0-4.83V52.34c0-3.23,0-6.46.07-9.68.09-6.45.15-12.91.33-19.36l.07-2.42,0-1.21c0-.41.06-.87.11-1.3a11,11,0,0,1,1.94-4.82,11.3,11.3,0,0,1,3.9-3.31,11.15,11.15,0,0,1,4.93-1.12,11.07,11.07,0,0,1,4.85,1.27,10.73,10.73,0,0,1,5.51,8c0,.42.08.85.09,1.26l0,1.22.08,2.44.36,9.81q.16,4.94.19,9.87C34.69,56.11,34.31,69.17,33.66,81.47Z"/>
      </g>
      <path d="M.68,81.37v52.2a29.26,29.26,0,0,0,29.17,29.17h0A29.26,29.26,0,0,0,59,133.57V81.37Z" fill="#e5d9d9"/>
      <g opacity="0.08">
        <path
          d="M44.24,131.16A29.28,29.28,0,0,1,23.63,159a27.85,27.85,0,0,0,3.55.23h0A29.26,29.26,0,0,0,56.35,130.1V81.37H44.24Z"/>
      </g>
      <g opacity="0.06">
        <path
          d="M28.3,154.51a31.09,31.09,0,0,0,11-15.31,30.13,30.13,0,0,0,1.34-9.28l-.06-9.71.06-9.71c0-3.24.07-6.47.17-9.71.15-6.47.37-13,.8-19.42.43,6.47.65,12.95.8,19.42.1,3.24.12,6.47.18,9.71l.05,9.71-.06,9.71c0,.4,0,.83-.05,1.24s0,.84-.08,1.25c-.09.83-.17,1.67-.34,2.48-.08.41-.13.83-.24,1.23l-.3,1.22c-.09.4-.24.8-.36,1.19s-.24.8-.4,1.18A28.24,28.24,0,0,1,28.3,154.51Z"/>
      </g>
      <g opacity="0.19">
        <path
          d="M44.24,31.67A29.28,29.28,0,0,0,23.63,3.79a29.81,29.81,0,0,1,3.55-.23h0A29.26,29.26,0,0,1,56.35,32.74V81.46H44.24Z"
          fill="#fff"/>
      </g>
      <g opacity="0.08">
        <path
          d="M4.53,133.57V81.37h.3a1,1,0,0,1-1-1V29.17c0-15.41,12.4-28.12,27.93-29.1C31.14,0,30.5,0,29.85,0h0C13.43,0,0,13.13,0,29.17V80.4a1,1,0,0,0,1,1H.68v52.2a29.26,29.26,0,0,0,29.17,29.17h0c.65,0,1.29,0,1.93-.07A29.26,29.26,0,0,1,4.53,133.57Z"/>
      </g>
      <g opacity="0.8">
        <path
          d="M4.53,133.55A29,29,0,0,0,8,147.25a33.49,33.49,0,0,1-1.36-9.46V81.37h.3a1,1,0,0,1-1-1.06V25a32.86,32.86,0,0,1,1.32-9.27A28.48,28.48,0,0,0,3.86,29.19v51.2a1,1,0,0,0,1,1h-.3Z"
          fill="#fff"/>
      </g>
      <g opacity="0.19">
        <path
          d="M20.67,142V20.9c0-5.53-2.1-10.11-4.78-10.73-2.68.62-4.79,5.2-4.79,10.73V142c0,5.52,2.11,10.11,4.79,10.73C18.57,152.14,20.67,147.55,20.67,142Z"
          fill="#fff"/>
      </g>
      <path d="M52.12,19.31c-1.84,3.19-8.57,2.74-15-1S26.87,9,28.71,5.8s8.57-2.75,15,1S54,16.12,52.12,19.31Z"
            fill="#fff" opacity="0.51"/>
      <path d="M53.56,28.81c-.85.3-2-.85-2.65-2.57s-.42-3.35.42-3.65,2,.84,2.65,2.56S54.4,28.5,53.56,28.81Z" fill="#fff"
            opacity="0.51"/>
      <rect height="0.71" opacity="0.21" width="58.35" x="0.68" y="81.37"/>
    </g>
  </svg>
</div>

<div class="text">
  {{ loadingText }}
</div>
